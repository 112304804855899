import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as Components from '~/ReactComponents';
import Loading from '~/ui-components/Loading';

const injectComponent = ({
   selector,
   component,
   props
}) => {
   const el = document.querySelector(selector);
   if (!el) {
      console.warn(`Couldn't find selector ${selector}`);
      return null;
   }

   const C = Components[component];
   if (!C) {
      console.warn(`${component} not found in ReactComponents exports.`);
      return null
   };
   
   try {
      ReactDOM.render((
         <Suspense fallback={<Loading/>}>
            <C {...props} />
         </Suspense>
      ), el);
   } catch (e) {
      console.warn(e);
   }
}

const $doc = jQuery(document);
export default function reactInjector(injections) {
   if (injections) {
      injections.forEach(r => (
         r.onDocumentReady
            ? $doc.ready(() => injectComponent(r))
            : injectComponent(r)
      ));
   }
}