
const clamp = (min, max, n) => Math.min(max, Math.max(min, n));

// scroll handlers
const $document = jQuery(document);
const $window = jQuery(window);
const $logo = jQuery('#full-logo');
const header = document.getElementById('site-header');

let logoHeight = $logo.height();
let scrollPos = $window.scrollTop();

let lastTranslate;
let blurApplied = false;
const handleScroll = () => {
   scrollPos = $window.scrollTop();
   if (!logoHeight) {
      logoHeight = $logo.height();
   }
   let translate = clamp(-logoHeight, 0, -scrollPos);
   if (!lastTranslate || translate !== lastTranslate) {
      lastTranslate = translate;
      window.requestAnimationFrame(() => {
         const transform = `translateY(${translate}px) translateX(-50%)`;
         $logo.css({
            transform: transform,
            '-webkit-transform': transform,
            '-moz-transform': transform
         });
         // applying blur after transform for performance
         if (!blurApplied && translate <= -logoHeight) {
            header.classList.add('blur');
            blurApplied = true;
         }
         else if (blurApplied && translate > -logoHeight) {
            header.classList.remove('blur');
            blurApplied = false;
         }
      });
   }
};
handleScroll();
$window.scroll(handleScroll);
$window.resize(function(){
   logoHeight = $logo.height();
   handleScroll();
});
$document.ready(handleScroll);
$document.load(handleScroll);

$document.on('click', '.site-header a', function (){
   jQuery('.site-header .current-menu-item').removeClass('current-menu-item');
   jQuery(this).addClass('active');
});

jQuery(".menu-item-has-children > a").click((function(e) {
   e.preventDefault(), jQuery(this).parent().toggleClass("expanded");
}));