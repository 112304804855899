function toggleSearchDropdown() {
   const $dropdown = jQuery('#site-header .btn-search')
      .closest('.wrap-search')
      .find('.search-dropdown');
   
   const closeSearch = (e) => {
      if (e.key === 'Escape') {
         $dropdown.removeClass('visible');
         document.removeEventListener('keydown', closeSearch);
      }
   }

   if ($dropdown.hasClass('visible')) {
      $dropdown.removeClass('visible');
      document.removeEventListener('keydown', closeSearch);
   }
   else {
      $dropdown.addClass('visible');
      setTimeout(function(){
         $dropdown.find('input').get(0).focus();
      }, 200);
      document.addEventListener('keydown', closeSearch);
   }
}

jQuery(document).on('click', '.btn-search', toggleSearchDropdown);

jQuery(document).on('keydown', function(e) {
   if (e.ctrlKey && e.key === 'f') {
      toggleSearchDropdown();
   }
})

