import {lazy} from 'react';

/**
 * Lazily export all react components here
 */

export const InfinitePosts = lazy(() => import('./components/posts/InfinitePosts'));
export const NavPosts = lazy(() => import('./components/posts/NavPosts'));
export const VideoPlayerPage = lazy(() => import('./components/video/VideoPlayerPage'));
export const JobsPage = lazy(() => import('./components/jobs/JobsPage'));
