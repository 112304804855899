const $ = jQuery.noConflict();

$(document).on('click', '#filter-search-results', function(){
   let categories = [];
   $('.search-filters input:checked').each(function(){
      categories.push($(this).attr('data-category-slug'));
   })
   const params = new URLSearchParams(location.search);
   if (categories.length) {
      params.set('categories', categories.join(' '));
   }
   else {
      params.delete('categories');
   }
   location.search = params.toString();
})
