/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import 'core-js';

// import 'regenerator-runtime';
import 'bootstrap';
import '../style/style.scss';
import themeLogger from './utils/ThemeLogger';
import reactInjector from './utils/ReactInjector';

// CONSTANTS
// ---------
// whether page view events should be sent to google analytics
export const GA_ENABLED = true;
// log google analytics page view events to the console
export const GA_LOGGING = _theme.loggedIn === '1';

import './header';
import "./search/search-events";
import "./search/search";

reactInjector(window.reactInjections);
themeLogger(window._themeLogs);

// enabled bootstrap tooltips
jQuery('document').ready(function(){
   jQuery('[data-toggle="tooltip"]').tooltip();
})

//import "./ads";